<template> 
	<div>
		<table cellpadding="0" cellspacing="0" class="plugins-table">
			
			<tbody>
				<tr v-for="(item,index) in myPlugins" :key="index">
					<td>
						{{myPlugins[index].name}}
					</td>
					
					<td class="flex alcenter end">
						<a-button type="link" @click="upAct(index)" v-if="index > 0" icon="arrow-up"></a-button>
						<a-button type="link" @click="downAct(index)" v-if="(index < myPlugins.length - 1 ) && myPlugins.length >1" icon="arrow-down"></a-button>
						<a-button type="link" @click="deleteAct(index)" icon="delete" style="color:#F76260"></a-button>
						
						<edit-search v-if="item.key == 'search'" v-model="myPlugins[index]"></edit-search>
						<edit-banner v-if="item.key == 'banner'" v-model="myPlugins[index]"></edit-banner>
						<edit-icon v-if="item.key == 'icon'" v-model="myPlugins[index]"></edit-icon>
						<edit-pics v-if="item.key == 'pics'" v-model="myPlugins[index]"></edit-pics>
						<edit-video v-if="item.key == 'video'" v-model="myPlugins[index]"></edit-video>
						<edit-miaosha v-if="item.key == 'miaosha'" v-model="myPlugins[index]"></edit-miaosha>
						<edit-booking v-if="item.key == 'booking'" v-model="myPlugins[index]"></edit-booking>
						<edit-pintuan v-if="item.key == 'pintuan'" v-model="myPlugins[index]"></edit-pintuan>
						<edit-store v-if="item.key == 'store'" v-model="myPlugins[index]"></edit-store>
						<edit-product v-if="item.key == 'product'" v-model="myPlugins[index]"></edit-product>
						<edit-coupon v-if="item.key == 'coupon'" v-model="myPlugins[index]"></edit-coupon>
						<edit-mendian v-if="item.key == 'mendian'" v-model="myPlugins[index]"></edit-mendian>
						<edit-tuan v-if="item.key == 'tuan'" v-model="myPlugins[index]"></edit-tuan>
						<edit-oncecard v-if="item.key == 'oncecard'" v-model="myPlugins[index]"></edit-oncecard>
						<edit-look v-if="item.key == 'look'" v-model="myPlugins[index]"></edit-look>
						
					</td>
				</tr>
			</tbody>
		</table>
		<div  class="flex center mt16">
			<a-button @click="addPlugins" type="link" icon="plus">新增组件</a-button>
		</div>
		
		<a-modal
			  width="480px"
		      title="添加组件"
		      :visible="showPlugins"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="flex wrap space">
				<div v-for="(item,index) in tempPlugins" :key="index" :class="{mt16:index > 1}" class="plugin-item">
					<span class="ft14 ftw600 cl-main">
						{{item.name}}
					</span>
					<div @click="addAct(index)" class="select-icon">
						<i  class="iconfont iconadd_big  ft12"></i>
					</div>
				</div>
			</div>
		</a-modal>
		
	</div>
</template>

<script>
	import {plugins} from './template.js';
	import editSearch from './setting/search.vue';
	import editBanner from './setting/banner.vue';
	import editIcon from './setting/icon.vue';
	import editPics from './setting/pics.vue';
	import editVideo from './setting/video.vue';
	import editMiaosha from './setting/miaosha.vue';
	import editBooking from './setting/booking.vue';
	import editPintuan from './setting/pintuan.vue';
	import editStore from './setting/store.vue';
	import editProduct from './setting/product.vue';
	import editMendian from './setting/mendian.vue';
	import editCoupon from './setting/coupon.vue';
	import editTuan from './setting/tuan.vue';
	import editOncecard from './setting/oncecard.vue';
	import editLook from './setting/look.vue';
	
	export default{
		components:{
			editSearch,
			editBanner,
			editIcon,
			editPics,
			editVideo,
			editMiaosha,
			editBooking,
			editPintuan,
			editStore,
			editProduct,
			editMendian,
			editCoupon,
			editTuan,
			editOncecard,
			editLook
		},
		props:{
			value:{
				type:Array,
				default:function(){
					return new Array;
				}
			}
		},
		watch:{
			myPlugins:{
				handler(newName, oldName){
					this.$emit('input',this.myPlugins);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.myPlugins){
						this.myPlugins = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				myPlugins:[],
				showPlugins:false,
				tempPlugins:[],
			}
		},
		created(){
			this.myPlugins = this.value;
			this.tempPlugins = plugins;
		},
		methods:{
			addPlugins(){
				this.showPlugins = true;
			},
			handleCancel(){
				this.showPlugins = false;
			},
			addAct(index){
				let plugins = JSON.parse(JSON.stringify(this.tempPlugins));
				let data = plugins[index];
				this.myPlugins.push(data);
				this.showPlugins = false;
			},
			deleteAct(index){
				this.myPlugins.splice(index,1);
			},
			downAct(index){
				let myPlugins = JSON.parse(JSON.stringify(this.myPlugins));
				let down = parseInt(index) + 1;
				let my = myPlugins[index];
				let d  = myPlugins[down];
				myPlugins[index] =  d;
				myPlugins[down]  = my;
				this.myPlugins = myPlugins;
			},
			upAct(index){
				let myPlugins = JSON.parse(JSON.stringify(this.myPlugins));
				let up = parseInt(index) - 1;
				let my = myPlugins[index];
				let u = myPlugins[up];
				myPlugins[index] =  u;
				myPlugins[up]  = my;
				this.myPlugins = myPlugins;
			},
		}
	}
</script>

<style>
	.plugins-table{
		background: #FFFFFF;
		width: 100%;
	}
	.plugin-item{
		width: 200px;
		height: 50px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.plugin-item .select-icon{
		width: 48px;
		height: 32px;
		background: #F5F6FA;
		border-radius: 16px;
		text-align: center;
		line-height: 32px;
		color:#AEB2C1;
	}
	.plugins-table tbody td{
		padding: 10px 20px;
	}
	.plugins-table tbody tr{
		border-bottom: 1px solid #E4E6ED;
	}
	.plugins-table tbody tr:last-child{
		border:none;
	}
</style>